import React, {useEffect} from 'react';
import css from './layout.module.scss';
import PropTypes from 'prop-types';
import CookieConsent from "react-cookie-consent";
import {graphql, useStaticQuery} from 'gatsby';
import {Link} from 'gatsby';
import {getLink, getRoutes} from '../shared/routes';
import {useTranslation} from 'react-i18next';
import Header from './Header/Header';
import '../../styles/reset.css';
import '../../locales/i18n';
import '../../styles/_global.scss';
import ProductFingerprintLogo from "../../images/logos/product_fingerprint_logo.svg";

// const Layout = ({ children, page = "home" }) => {
  const Layout = ({ children,}) => {

  const { t } = useTranslation();

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            companyUrl
          }
        }
      }
    `
  );

  // https://github.com/cferdinandi/smooth-scroll
  const addSmoothScrolling = () => {
    require("smooth-scroll")('a[href*="#"]', {
      speed: 300,
      topOnEmptyHash: false,
      offset: function (anchor, toggle) {
        const menuLevel1Active = document.querySelectorAll('[data-menu-active="true"]');
        if (menuLevel1Active.length) {
          return menuLevel1Active[0].offsetHeight;
        }

        return 0;
	  }
      // speedAsDuration: false
    });
  };

  useEffect(() => {
    addSmoothScrolling();
  },[]);

  const renderPasswordPolicyLink = () => {
    const companyUrl = site.siteMetadata.companyUrl;
    return <a href="/files/SCH_Swapan_Chaudhuri_Privacy_Policy.pdf" target="_blank">Learn more</a>
  };

  const cookieButtonStyle = {
    border: '2px solid var(--mainGreenColor)',
    borderRadius: '100px',
    background: 'var(--mainGreenColor)',
    fontSize: '.7rem',
    fontWeight: 700,
    letterSpacing: '1.08px',
    color: 'var(--mainWhiteColor)',
    padding: '4px 16px',
  };

  const linkToHome = getLink([getRoutes().home]);

  return (
    <>
      <div className={css.mobileLogoWrapper}>
        <Link to={linkToHome} className={css.mobileLogoLink}>
          <img src={ProductFingerprintLogo} className={css.ProductFingerprintLogo} alt='deeplai productfingerprint product fingerprint logo logotype'/>
        </Link>
      </div>

      {/* <Header page={page} /> */}
      <Header/>

      <main className="main">{children}</main>

      <div className={css.fixedItemsWrapper}>
        {/*{ page === 'home' && <HomepageSplash /> }*/}
        <CookieConsent
          location="bottom"
          buttonText={"OK"}
          buttonStyle={cookieButtonStyle}
          style={{ position: 'relative', width: '100vw', transform: 'translateX(-100%)', padding: '0 100px 0 20px', boxSizing: 'border-box' }}
          contentStyle={{ flex: '1 0 260px' }}
          expires={30}
        >
          <span className={css.cookieText}>
            This website uses cookies to ensure you get the best experience on our website.{" "}
          </span>
          <span className={css.passwordPolicyLink}>{renderPasswordPolicyLink()}</span>
        </CookieConsent>
      </div>

    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
