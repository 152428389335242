import React, {useEffect, useState} from "react";
import {Link} from "gatsby";
import {getLink, getRoutes} from "../routes";
import Submenu from "../Submenu/Submenu";

const SubpageLink = props => {
  const {
    subpage,
    children = '',
    submenu = [],
    menuLevel = 0,
    mobile = false
  } = props;

  const [active, setActive] = useState(false);

  const link = subpage ? getLink(subpage) : '';

  useEffect(() => {
    const currentPage = window.location.pathname;
    const isCurrentSubpage = (link === currentPage);
    if (isCurrentSubpage) {
      setActive(true);
      return;
    }
    const isCurrentSubpageLinkToHome = (link === getLink([getRoutes().home]));
    // i.e. "/user_guide/" is parent link for "/user_guide/how-optiwai-manages-the-language-preferences/"
    const isParentOfCurrentSubpage = !isCurrentSubpage && !isCurrentSubpageLinkToHome && currentPage.startsWith(link);
    if (isParentOfCurrentSubpage) {
      setActive(true);
      return;
    }
  },[]);

  if (!link) {
    return null;
  }

  const content = <span data-active={active}>{children}</span>;

  return (
    <>
      <Link to={link} data-active={active}>
        {content}
      </Link>
      {(submenu.length > 0) && <Submenu active={active} menuLevel={menuLevel + 1} mobile={mobile}>{submenu}</Submenu>}
    </>
  );
};

export default SubpageLink;
