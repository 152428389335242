import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import enTranslations from '../locales/en/translation';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    lng: 'en',
    ns: ['common'],
    defaultNS: 'common',
    resources: {
      en: {
        common: enTranslations
      }
    },
    debug: process.env.NODE_ENV === 'development',
    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    react: {
      // bindI18n: 'languageChanged',
      // bindI18nStore: '',
      // transEmptyNodeValue: '',
      // transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'nobr', 'app-red', 'app-trademark', 'app-plus', 'app-nobreak', 'div'],
      // useSuspense: true,
      wait: true
    }
  });


export default i18n;
