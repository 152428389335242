import {graphql, Link, useStaticQuery} from 'gatsby';
import React, {useEffect, useState} from 'react';
import {getLink, getRoutes} from '../routes';
import {breakpoints} from "../../componentsUtils";
import useBreakpoint from "../../hooks/useBreakpoint";
import css from './header.module.scss';
import {useTranslation} from 'react-i18next';
import HeaderLink from '../HeaderLink/HeaderLink.js';
import ProductFingerprintLogo from '../../../images/logos/product_fingerprint_logo.svg';
import MainButton from "../MainButton/MainButton";
import ModelsLogo from "../../../images/logos/deeplai_models_logo.svg";
import BackgroundImg from "../../../images/home/green_background.svg";
// import MenuDropdownArrows from "../MenuDropdownArrows/MenuDropdownArrows";

/*
NOTE:
First version contained modal which are turned off now.
 */

let Header;

Header = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            webAppUrl
            optiplusWebAppUrl
          }
        }
      }
    `
  );

  const { t } = useTranslation();

  const breakpoint = useBreakpoint();
  const mobile = breakpoint.width <= breakpoints.lg;

  const mobileClass = mobile ? css.mobile : '';

  const tight = !mobile;

  const [open, setOpen] = useState(false);

  const openClass = open ? css.open : '';

  const toggleMenu = ()  => {
    if (mobile) {
      setOpen(!open);
    }
  };

  const closeMenu = ()  => {
    setOpen(false);
  };

  const toggleHtmlNodeScroll = () => {
    const htmlNode = document.body.parentElement;
    const scrollbarWidth = window.innerWidth - document.body.clientWidth;
    if (open) {
      htmlNode.style.paddingRight = `${scrollbarWidth}px`;
      htmlNode.style.overflow = 'hidden';
    } else {
      htmlNode.style.paddingRight = '0px';
      htmlNode.style.overflow = null;
    }
  };

  useEffect(() => {
    toggleHtmlNodeScroll();
  }, [open]);

  useEffect(() => {
    closeMenu();
  }, [mobile]);


  const renderMenu = (menu, menuLevel) => {
    return menu.map((item, index) => {
      const {
        key = `menu_${menuLevel || 0}_${index}`,
        page,
        customItemRender,
        borderedAndGreen,
        isDropdown = false,
        label,
        labelHidden,
        submenu = [],
        renderCustomSubmenuFn,
        setActiveForSubpages
      } = item;
      const submenuLevel = isDropdown ? -1 : menuLevel + 1;
      const submenuToRender = submenu.length ? renderMenu(submenu, submenuLevel) : [];
      return (
        <HeaderLink
          key={key}
          subpage={page}
          customItemRender={customItemRender}
          borderedAndGreen={borderedAndGreen}
          isDropdown={isDropdown}
          text={label}
          textHidden={labelHidden}
          // separator
          mobile={mobile}
          onClick={() => {
            if (!isDropdown) {
              closeMenu();
            }
          }}
          submenu={submenuToRender}
          renderCustomSubmenuFn={renderCustomSubmenuFn}
          menuLevel={menuLevel}
          setActiveForSubpages={setActiveForSubpages}
        />
      );
    });
  };

  // NOTE: Don't remove
  // const renderHeaderSectionLink = (section, text) => {
  //   return (
  //     <HeaderLink
  //       section={section}
  //       currentPage={page}
  //       text={text}
  //       // logo={<img src={ShotsLogoMenu} alt="" className={css.ShotsLogoMenu}/>}
  //       // logoActive={<img src={ShotsLogoMenuActive} alt="" className={css.ShotsLogoMenu}/>}
  //       // separator
  //       tight={tight}
  //       mobile={mobile}
  //       onClick={() => closeMenu()}
  //     />
  //   );
  // };

  const mainMenu = [{
    page: [getRoutes().home],
    label: 'Home',
  },  {
    page: [getRoutes().UseCases],
    label: 'Use Cases',
  }, {
    page: [getRoutes().news], // News
    label: 'News',
    submenu: []
  }, {
    page: [getRoutes().Contact], // Contact
    label: 'Contact',
  }];

  return (
    <header className={[css.header, css.headerTight, mobileClass, openClass].join(' ')}>

      <div className={css.overlay} onClick={() => toggleMenu()} />

      <div className={css.hamburger} onClick={() => toggleMenu()}>
        <div className={css.line} />
        <div className={css.line} />
        <div className={css.line} />
      </div>

      <div className={css.wrapper}>

        <div className={css.logoWrapper}>
          <Link to={getLink([getRoutes().home])} className={css.logoLink} onClick={() => toggleMenu()}>
            <img src={ProductFingerprintLogo} className={css.companyLogo} alt='deeplai productfingerprint product fingerprint logo logotype'/>
          </Link>
          <div className={css.closeButton} onClick={() => toggleMenu()}>
            <div className={css.closeButtonInner}/>
          </div>
        </div>
        <nav>
          {renderMenu(mainMenu, 0)}
        </nav>
      </div>

    </header>
  );
};

export default Header;
