import React from 'react';
import css from './mainButton.module.scss';

const MainButton = props => {
  const {
    isLink = true,
    label = '',
    filled = false,
    className,
    href = '#',
    tight = false,
    mobile = false,
    bigger = false,
    color = 'mainRed'
  } = props;
  const filledClass = filled ? css.filled : '';
  const tightClass = tight ? css.tight : '';
  const biggerClass = bigger ? css.bigger : '';
  const mobileClass = mobile ? css.mobile : '';

  const getColorClass = () => {
    const color = !!props.color ? props.color : 'mainRed';
    switch (color) {
      case 'green':
        return css.buttonGreen;
      default:
        return css.buttonRed;
    }
  };

  if (isLink) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={[css.mainButton, getColorClass(), filledClass, tightClass, biggerClass, mobileClass, className].join(' ')}
      >
        {label}
      </a>
    );
  }

  return (
    <div className={[css.mainButton, getColorClass(), filledClass, tightClass, biggerClass, mobileClass, className].join(' ')}>
      {label}
    </div>
  );

};

export default MainButton;
