// NOTE:
// 1. Every "main" route has to ends with "/". If not - navigation link won't be set as active on page refresh.
// 2. Check if there is something to change in gatsby-plugin-sitemap in gatsby-config.
import React from "react";

const routes = {
  home: {
    main: '/',
    sections: {
      getStarted: 'get_started'
    }
  },
  news: {
    pageTitle: 'News',
    main: '/news/', // NOTE: the same as siteMetadata.newsListUrl in gatsby-config.js
    sections: {}
  },
  Contact: {
    pageTitle: 'Contact',
    main: '/contact/'
  },
 
  UseCases: {
    pageTitle: 'Use Cases',
    main: '/use-cases/'
  }
};

export const getRoutes = () => {
  return routes;
};


// export const getLink = (route, sectionWithPage = false) => {
  
//   if (Array.isArray(route)) { // TODO temporary, refactor
//     return getLink2(route, sectionWithPage);
//   }

//   const indexRoute = routes.home.main;
//   if (typeof route !== 'string') {
//     return indexRoute;
//   }


//   const getPage = () => {
//     const page = routes[routesArray[0]];
//     if (!page) {
//       console.error('! route not found: ', routesArray[0]);
//     }
//     return page;
//   };
//   const getSection = page => page.sections[routesArray[1]];

//   const routesArray = route.split('.');
//   switch (routesArray.length) {
//     case 1: {
//       return getPage().main;
//     }
//     case 2: {
//       const page = getPage();
//       const section = getSection(page);
//       return sectionWithPage ? `${page.main}#${section}` : `#${section}`;
//     }
//     default:
//       return indexRoute;
//   }
// };


export const getLink = (routesArray = [], sectionWithPage = false) => {
  const indexRoute = routes.home.main;

  const getPage = () => {
    const page = routesArray[0];
    if (!page) {
      console.error('! route not found: ', routesArray[0]);
    }
    return page;
  };

  switch (routesArray.length) {
    case 1: {
      return getPage().main;
    }
    case 2: {
      const page = getPage();
      const section = routesArray[1];
      return sectionWithPage ? `${page.main}#${section}` : `#${section}`;
    }
    default:
      return indexRoute;
  }
};

export const getId = route => {
  
 
  const defaultId = '';
  if (typeof route !== 'string') {
    return defaultId;
  }

  const getPage = () => routes[routesArray[0]];
  const getSection = page => page.sections[routesArray[1]];

  const routesArray = route.split('.');
  if (routesArray.length !== 2) {
    return defaultId;
  }
  const page = getPage();
  return getSection(page);
};


