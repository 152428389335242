import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {graphql, useStaticQuery} from 'gatsby';
import packageJSON from '../../../package.json';

function SEO({ description, lang, meta, title = '' }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            author
            pinterestDomainVerify
            siteUrl
            companyName
            companyUrl
            socialLinks {
              facebook
              twitter
              instagram
              linkedin
              youtube
              pinterest
            }
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const pageTitle = site.siteMetadata.title;
  const metaTitleWithTags = title ? `${title} | ${pageTitle}` : pageTitle;
  const metaTitle = metaTitleWithTags.replace(/(<([^>]+)>)/gi, "");

  const appVersion = packageJSON.version;
  const siteUrl = site.siteMetadata.siteUrl;
  const companyName = site.siteMetadata.companyName;
  const companyUrl = site.siteMetadata.companyUrl;
  const socialLinks = site.siteMetadata.socialLinks;
  const siteImage = `${siteUrl}/thumbnail.jpg`;

  const structuredDataOrganization = `{
		"@context": "http://schema.org",
		"@type": "Organization",
		"name": "${companyName}",
		"url": "${companyUrl}",
		"logo": "${siteImage}",
		"sameAs": [
			"${socialLinks.facebook}",
			"${socialLinks.twitter}",
			"${socialLinks.instagram}",
			"${socialLinks.linkedin}",
			"${socialLinks.youtube}",
			"${socialLinks.pinterest}"
		]
  	}`

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={metaTitle}
      meta={[
        {
          name: 'viewport',
          content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        },
        {
          name: `version`,
          content: appVersion
        },
        {
          name: `description`,
          content: metaDescription
        },
        {
          name: `keywords`,
          content: site.siteMetadata.keywords,
        },
        {
          name: `author`,
          content: site.siteMetadata.author,
        },
        {
          name: `p:domain_verify`,
          content: site.siteMetadata.pinterestDomainVerify,
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: 'og:url',
          content: siteUrl
        },
        {
          property: `og:title`,
          content: metaTitle
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: 'og:image',
          content: siteImage
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`
        },
        {
          name: 'twitter:url',
          content: siteUrl
        },
        {
          name: `twitter:title`,
          content: metaTitle
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          name: 'twitter:image:src',
          content: siteImage
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        }
      ].concat(meta)}
      script={[{
        type: 'application/ld+json',
        innerHTML: structuredDataOrganization
      }]}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object)
};

export default SEO;
